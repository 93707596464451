import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { CompanyRecipient, KWLRecipient, SanctionStatus } from '../../interfaces/beneficiary'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'
import InnerHeader from '../../components/innerHeader'
import BeneficiaryForm from '../../components/beneficiaries/BeneficiaryForm'
import BeneficiaryLookUp from '../../components/beneficiaries/BeneficiaryLookUp'
import { recipientCountryInLocalStorage } from '../../helpers/localStorage/recipientCountry'
import { recipientDetailsInLocalStorage } from '../../helpers/recipientData'
import { PuffLoader } from 'react-spinners'
import { AmlStatus } from '../../interfaces/user'
import Modal from '../../components/Modal'
import { userActions } from '../../redux/action-creators/userActions'
import { errorConstants } from '../../redux/constants/errorConstants'
import AmlInspectionSection from '../../components/beneficiaries/AmlInspectionSection'

type LocationState = {
  state: {
    from: string
    searchedValue: string
    editMode: boolean
    shouldItMoveToAmlCheck: boolean
  }
  pathname: string
}

const BeneficiaryPage = () => {
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const dispatch = useAppDispatch()
  const { beneficiaryId } = useParams()
  const isNewBeneficiary = location.pathname.includes('new')
  const from = location?.state?.from || '/beneficiaries'
  const searchedValue = location?.state?.searchedValue || undefined
  const editMode = location?.state?.editMode || ''
  const shouldItMoveToAmlCheck = location?.state?.shouldItMoveToAmlCheck || false
  const {
    recipient,
    isLoading,
    isLoaded,
    isLookUpLoading,
    beneficiaryFieldsConfig,
    // beneficiaryCreationLoadState,
    // beneficiaryUpdateLoadState,
    // countryAllList: countryList,
  } = useAppSelector(state => state.beneficiary)
  const enableLookUp = useAppSelector(state => state.preset.preset?.enableLookUp)
  const userAmlStatus = useAppSelector(state => state.user.userAml?.status)
  const flowList = useAppSelector(state => state.preset.availableFlow)

  const recipientCountry = recipientCountryInLocalStorage.getRecipientCountry()

  const [canFormChange, setCanFormChange] = useState(false)
  const [isLookUpPage, setIsLookUpPage] = useState(false)
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false)
  const [isAmlInspectionSection, setIsAmlInspectionSection] = useState(false)

  useEffect(() => {
    if (
      !isNewBeneficiary &&
      shouldItMoveToAmlCheck &&
      (recipient.amlInspectionStatus === AmlStatus.Initiated ||
        recipient.amlInspectionStatus === AmlStatus.Pending ||
        recipient.amlInspectionStatus === AmlStatus.RejectedWithRetry)
    ) {
      setIsAmlInspectionSection(true)
    }
  }, [isNewBeneficiary, recipient.amlInspectionStatus, shouldItMoveToAmlCheck])

  useEffect(() => {
    const isLookUpPage = recipientCountry?.label === 'Zimbabwe' && isNewBeneficiary && enableLookUp

    if (isLookUpPage) {
      setIsLookUpPage(true)
    } else {
      setIsLookUpPage(false)
    }
  }, [enableLookUp, isNewBeneficiary, recipientCountry?.label])

  useEffect(() => {
    if (editMode || isNewBeneficiary) {
      setCanFormChange(true)
    }
  }, [editMode, isNewBeneficiary])

  useEffect(() => {
    if (
      beneficiaryFieldsConfig?.Company.BankName.visible ||
      beneficiaryFieldsConfig?.Individual.BankName.visible
    ) {
      dispatch(beneficiaryActions.getProviderListForWithdrawal())
    }
  }, [
    beneficiaryFieldsConfig?.Company.BankName.visible,
    beneficiaryFieldsConfig?.Individual.BankName.visible,
    dispatch,
  ])

  const getBeneficiaryData = useCallback(() => {
    dispatch(beneficiaryActions.getBeneficiaryFieldsConfig())

    if (beneficiaryId) {
      dispatch(beneficiaryActions.getRecipient(beneficiaryId, navigate))
    }
  }, [beneficiaryId, dispatch, navigate])

  useEffect(() => {
    getBeneficiaryData()

    return () => dispatch(beneficiaryActions.clearRecipient())
  }, [dispatch, getBeneficiaryData])

  const onGoBack = () => {
    const isEnabledLookup =
      recipientCountry?.label === 'Zimbabwe' && isNewBeneficiary && enableLookUp

    dispatch(beneficiaryActions.clearRecipient())

    if (isEnabledLookup) {
      if (isLookUpPage) {
        navigate(from, { state: { searchedValue } })
        return
      } else {
        setIsLookUpPage(true)
        return
      }
    }

    navigate(from, { state: { searchedValue } })
    return
  }

  const onCancel = useCallback(() => {
    if (!isNewBeneficiary && canFormChange) {
      if (editMode) {
        navigate(from, { state: { searchedValue } })
        return
      } else {
        setCanFormChange(false)
        return
      }
    }

    navigate(from, { state: { searchedValue } })
    return
  }, [canFormChange, editMode, from, isNewBeneficiary, navigate, searchedValue])

  const moveAfterAmlCheck = useCallback(
    (userAmlStatus: AmlStatus | undefined) => {
      if (beneficiaryId) {
        if (userAmlStatus === AmlStatus.Verified) {
          recipientDetailsInLocalStorage.saveRecipientDetails({
            ...recipient,
            ...{ id: +beneficiaryId },
          })
          navigate('/transaction/new', {
            state: { from: `/beneficiary/${beneficiaryId}`, searchedValue },
          })
        } else {
          setIsOpenVerificationModal(true)
        }
      }
    },
    [beneficiaryId, navigate, recipient, searchedValue]
  )

  const doAfterCreation = useCallback(() => {}, [])

  const sendRequest = useCallback(
    (recipientData: KWLRecipient | Omit<CompanyRecipient, 'countryId'>) => {
      if (beneficiaryId) {
        if (canFormChange) {
          dispatch(
            beneficiaryActions.updateRecipient(
              beneficiaryId,
              recipientData,
              navigate,
              from,
              searchedValue
            )
          )
        } else {
          if (recipient.sanctionStatus === SanctionStatus.OnSanctionsListUnconfirmed) {
            dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '4000000254' } })
            return
          }

          if (recipient.sanctionStatus === SanctionStatus.OnSanctionsListManualConfirmed) {
            dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '4000000253' } })
            return
          }

          dispatch(userActions.getUserAmlList(flowList, moveAfterAmlCheck))
        }
      } else {
        dispatch(beneficiaryActions.createRecipient(recipientData, navigate, from, userAmlStatus))
      }
    },
    [
      beneficiaryId,
      canFormChange,
      dispatch,
      flowList,
      from,
      moveAfterAmlCheck,
      navigate,
      recipient.sanctionStatus,
      searchedValue,
      userAmlStatus,
    ]
  )

  const confirmModalFunction = useCallback(() => {
    setIsOpenVerificationModal(false)

    if (userAmlStatus === AmlStatus.Pending) {
      navigate('/pending')
      return
    }

    navigate('/verification')
  }, [navigate, userAmlStatus])

  const textPage = useMemo(() => {
    if (!beneficiaryId) {
      return {
        textTitle: 'Add Beneficiary',
        textButton: 'Save',
      }
    }

    if (canFormChange) {
      return {
        textTitle: 'Editing Beneficiary Details',
        textButton: 'Save Details',
      }
    } else {
      return {
        textTitle: 'Beneficiary Details',
        textButton: 'Send Money',
      }
    }
  }, [beneficiaryId, canFormChange])

  const onAmlCheckingBtn = useCallback(() => {
    setIsAmlInspectionSection(true)
  }, [])

  const renderScreen = useMemo(() => {
    if (isLookUpPage) {
      return <BeneficiaryLookUp onCancel={onCancel} setIsLookUpPage={setIsLookUpPage} />
    }

    if (isAmlInspectionSection) {
      return <AmlInspectionSection id={beneficiaryId} />
    } else {
      return (
        <>
          <BeneficiaryForm
            recipient={recipient}
            recipientCountry={!beneficiaryId ? recipientCountry : null}
            sendRequest={sendRequest}
            setCanFormChange={setCanFormChange}
            onCancel={onCancel}
            canFormChange={canFormChange}
            id={beneficiaryId}
            isNewBeneficiary={isNewBeneficiary}
            textButton={textPage.textButton}
            isShownEditBtn
            type="beneficiary"
            onAmlCheckingBtn={onAmlCheckingBtn}
          />
        </>
      )
    }
  }, [
    beneficiaryId,
    canFormChange,
    isAmlInspectionSection,
    isLookUpPage,
    isNewBeneficiary,
    onAmlCheckingBtn,
    onCancel,
    recipient,
    recipientCountry,
    sendRequest,
    textPage.textButton,
  ])

  return (
    <>
      {isLookUpLoading && (
        <div className="spinner spinner-fixed">
          <PuffLoader size={70} color="#3171d8" />
        </div>
      )}
      {/* {!isLoading && isLoaded && Boolean(modifiedCountryList.length) && ( */}
      {!isLoading && isLoaded && (
        <section className="h-100-mb">
          <div className="container container-md container-100">
            <InnerHeader title={textPage.textTitle} onGoBack={onGoBack} />

            <div className="beneficiary-details-page">{renderScreen}</div>
          </div>
        </section>
      )}
      <Modal
        setIsOpenModal={setIsOpenVerificationModal}
        isOpenModal={isOpenVerificationModal}
        modalHeader="Verification"
        modalBody="For security reasons, please, complete the Identity Verification."
        modalType="informationModal"
        confirmFunction={confirmModalFunction}
      />
    </>
  )
}

export default BeneficiaryPage
