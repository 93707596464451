import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  BeneficiaryType,
  CompanyBeneficiaryFormType,
  CompanyRecipient,
  CountryOption,
  IndividualBeneficiaryFormType,
  KWLRecipient,
} from '../../interfaces/beneficiary'
import {
  documentTypeOptionList,
  getBeneficiaryTypeOptions,
  getCurrentBeneficiaryTypeOptions,
} from '../../helpers/configs/optionConfigs'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'
import IndividualBeneficiaryForm from './IndividualBeneficiaryForm'
import {
  getAddressSectionCountryOptions,
  getCountryPhoneCodeOptions,
  getDocumentCountryOptions,
  getProviderForWithdrawalOptions,
} from './beneficiaryFormUtils'
import CustomNativeSelector from '../interfaceComponents/CustomNativeSelector'
import CompanyBeneficiaryForm from './CompanyBeneficiaryForm'
import isEqual from 'lodash/isEqual'
import { PuffLoader } from 'react-spinners'

const countryOptionsForCompany = [
  {
    value: 240,
    label: 'Zimbabwe',
  },
]

type BeneficiaryFormProps = {
  recipient: KWLRecipient | CompanyRecipient
  recipientCountry?: CountryOption | null
  id: string | undefined
  sendRequest: (recipient: KWLRecipient | Omit<CompanyRecipient, 'countryId'>) => void
  setCanFormChange: Dispatch<SetStateAction<boolean>>
  textButton: string
  canFormChange: boolean
  isShownEditBtn: boolean
  onCancel: () => void
  isNewBeneficiary: boolean
  type: 'recipient' | 'beneficiary'
  onAmlCheckingBtn?: () => void
}

const BeneficiaryForm = ({
  recipient,
  recipientCountry,
  sendRequest,
  setCanFormChange,
  onCancel,
  textButton,
  canFormChange,
  isShownEditBtn,
  isNewBeneficiary,
  type,
  onAmlCheckingBtn,
}: BeneficiaryFormProps) => {
  const dispatch = useAppDispatch()

  const { recipientCountryList: countryList } = useAppSelector(state => state.beneficiary)
  const beneficiaryFieldsConfig = useAppSelector(
    state => state.beneficiary.beneficiaryFieldsConfig,
    isEqual
  )
  const {
    beneficiaryCreationLoadState,
    beneficiaryUpdateLoadState,
    validateWithdrawalAccountNumberLoadState,
  } = useAppSelector(state => state.beneficiary)
  const providerListForWithdrawal = useAppSelector(
    state => state.beneficiary.providerListForWithdrawal
  )
  const updateRecipientInTopUpLoadState = useAppSelector(
    state => state.remittance.updateRecipientInTopUpLoadState
  )

  const [beneficiaryType, setBeneficiaryType] = useState<BeneficiaryType | undefined>()

  const isLoading = useMemo(() => {
    return (
      beneficiaryCreationLoadState.isLoading ||
      beneficiaryUpdateLoadState.isLoading ||
      updateRecipientInTopUpLoadState.isLoading ||
      validateWithdrawalAccountNumberLoadState.isLoading
    )
  }, [
    beneficiaryCreationLoadState.isLoading,
    beneficiaryUpdateLoadState.isLoading,
    updateRecipientInTopUpLoadState.isLoading,
    validateWithdrawalAccountNumberLoadState.isLoading,
  ])

  useEffect(() => {
    setBeneficiaryType(recipient.type || 0)
  }, [recipient.type])

  useEffect(() => {
    if (isEmpty(countryList)) {
      dispatch(beneficiaryActions.getBeneficiaryCountryList())
    }
  }, [countryList, dispatch])

  const beneficiaryTypeOptions = useMemo(() => {
    if (isNewBeneficiary) {
      return beneficiaryFieldsConfig
        ? getCurrentBeneficiaryTypeOptions(beneficiaryFieldsConfig)
        : []
    } else {
      return beneficiaryFieldsConfig ? getBeneficiaryTypeOptions(beneficiaryFieldsConfig) : []
    }
  }, [beneficiaryFieldsConfig, isNewBeneficiary])

  const countryPhoneCodeOptions = useMemo(
    () => getCountryPhoneCodeOptions(countryList),
    [countryList]
  )

  const documentCountryOptions = useMemo(
    () => getDocumentCountryOptions(countryList),
    [countryList]
  )

  const addressSectionCountryOptions = useMemo(
    () => getAddressSectionCountryOptions(countryList),
    [countryList]
  )

  const documentTypeOptions = useMemo(
    () => documentTypeOptionList(beneficiaryFieldsConfig),
    [beneficiaryFieldsConfig]
  )

  const providerListForWithdrawalOptions = useMemo(() => {
    if (
      providerListForWithdrawal.length > 0 &&
      (beneficiaryType || beneficiaryType === BeneficiaryType.Individual)
    ) {
      return getProviderForWithdrawalOptions(providerListForWithdrawal, beneficiaryType)
    }
  }, [beneficiaryType, providerListForWithdrawal])

  const toggleEditingForm = () => {
    setCanFormChange(true)
  }

  const onFormConfirmation = useCallback(
    (recipient: IndividualBeneficiaryFormType | Omit<CompanyBeneficiaryFormType, 'countryId'>) => {
      const fullRecipient = { ...recipient, type: beneficiaryType || BeneficiaryType.Individual }

      sendRequest(fullRecipient)
    },
    [beneficiaryType, sendRequest]
  )

  const onChangeBeneficiaryType = useCallback((value: string) => {
    setBeneficiaryType(Number(value))
  }, [])

  return beneficiaryFieldsConfig && typeof beneficiaryType !== 'undefined' ? (
    <>
      {isLoading && (
        <div className="spinner spinner-fixed">
          <PuffLoader size={70} color="#3171d8" />
        </div>
      )}
      <div className="details-section__title">
        <h2>Beneficiary Type</h2>
      </div>
      <CustomNativeSelector
        value={beneficiaryType}
        className="select-form"
        options={beneficiaryTypeOptions}
        onParentChange={onChangeBeneficiaryType}
        disabled={!isNewBeneficiary}
      />
      {beneficiaryType === 0 && (
        <IndividualBeneficiaryForm
          recipient={recipient as KWLRecipient}
          beneficiaryFieldsConfig={beneficiaryFieldsConfig}
          canFormChange={canFormChange}
          isShownEditBtn={isShownEditBtn}
          recipientCountry={recipientCountry}
          toggleEditingForm={toggleEditingForm}
          sendRequest={onFormConfirmation}
          onCancel={onCancel}
          textButton={textButton}
          countryPhoneCodeOptions={countryPhoneCodeOptions}
          documentCountryOptions={documentCountryOptions}
          addressSectionCountryOptions={addressSectionCountryOptions}
          documentTypeOptions={documentTypeOptions}
          type={type}
          {...(providerListForWithdrawalOptions && { providerListForWithdrawalOptions })}
        />
      )}
      {beneficiaryType === 1 && (
        <CompanyBeneficiaryForm
          recipient={recipient as CompanyRecipient}
          canFormChange={canFormChange}
          isShownEditBtn={isShownEditBtn}
          toggleEditingForm={toggleEditingForm}
          sendRequest={onFormConfirmation}
          onCancel={onCancel}
          textButton={textButton}
          beneficiaryFieldsConfig={beneficiaryFieldsConfig}
          type={type}
          countryOptionsForCompany={countryOptionsForCompany}
          isNewBeneficiary={isNewBeneficiary}
          {...(onAmlCheckingBtn && { onAmlCheckingBtn })}
          {...(providerListForWithdrawalOptions && { providerListForWithdrawalOptions })}
        />
      )}
    </>
  ) : null
}

export default BeneficiaryForm
