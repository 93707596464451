import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'
import SumsubWebSdk from '@sumsub/websdk-react'

type AmlInspectionSectionProps = {
  id: string | undefined
}

const AmlInspectionSection = ({ id }: AmlInspectionSectionProps) => {
  const dispatch = useAppDispatch()

  const token = useAppSelector(state => state.beneficiary.accessTokenForAmlCompanyChecking)

  useEffect(() => {
    id && dispatch(beneficiaryActions.getAmlAccessTokenForCompanyBeneficiary(id))
  }, [dispatch, id])

  const goToPendingPage = async (type: any) => {
    // if (type === 'idCheck.onApplicantSubmitted' || type === 'idCheck.onApplicantResubmitted') {
    //   await dispatch(userActions.getUserAmlList(flowList))
    //   navigate('/pending')
    // }
    // if (type === 'idCheck.applicantReviewComplete') {
    //   setTimeout(() => {
    //     dispatch(userActions.getUserAmlList(flowList))
    //   }, 3000)
    // }
  }
  return token ? (
    <SumsubWebSdk
      accessToken={token}
      expirationHandler={() => Promise.resolve(token)}
      onMessage={type => goToPendingPage(type)}
      config={{
        uiConf: {
          customCssStr:
            ':root {\n  --black: #24E2A0;\n --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n --primary-color: #221772; \n --success-color: #3171D8; }\n body { font-family: Roboto, sans-serif;}\n #loader { background-color: #fff; padding: 60px 20px; } \n p {\n  \n  font-size: 16px !important;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  \n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: #fff;\n  color: var(--blue-color);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\n .checkbox .checkmark { margin-top: 4px; } \n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--primary-color);\n}\n\n.round-icon {\n  background-color: var(--primary-color) !important;\n  background-image: none !important;\n}',
        },
      }}
    />
  ) : null
}

export default AmlInspectionSection
