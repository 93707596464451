import { Recipient } from '../../helpers/initialModel'
import { BeneficiaryState, BeneficiaryActions } from '../../interfaces/redux-types/beneficiary'
import { beneficiaryConstants } from '../constants/beneficiaryConstants'

export const initialState: BeneficiaryState = {
  error: undefined,
  isLoaded: false,
  isLoading: false,
  isLookUpLoaded: false,
  isLookUpLoading: false,
  recipientCountryList: [],
  countryAllList: [],
  beneficiariesList: { items: [], hasNextPage: false },
  recipient: new Recipient(),
  beneficiaryFieldsConfig: null,
  beneficiaryCreationLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  beneficiaryUpdateLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  providerListForWithdrawal: [],

  withdrawalAccountNumber: '',
  withdrawalBankId: '',
  withdrawalAccountNumberInfo: null,
  validateWithdrawalAccountNumberLoadState: {
    isLoading: false,
    isLoaded: false
  },

  createMobileWalletRecipientLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  createMobileWalletRecipientError: '',

  companyListForBeneficiary: [],
  accessTokenForAmlCompanyChecking: '',
}

export const BeneficiaryReducer = (
  state = initialState,
  action: BeneficiaryActions
): BeneficiaryState => {
  switch (action?.type) {
    case beneficiaryConstants.GET_BENEFICIARY_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      }
    case beneficiaryConstants.GET_BENEFICIARY_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        recipientCountryList: action.payload,
      }
    case beneficiaryConstants.GET_BENEFICIARY_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case beneficiaryConstants.GET_COUNTRY_ALL_LIST_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      }
    case beneficiaryConstants.GET_COUNTRY_ALL_LIST_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        countryAllList: action.payload,
      }
    case beneficiaryConstants.GET_COUNTRY_ALL_LIST_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case beneficiaryConstants.CLEAR_RECIPIENT_SUCCESS:
      return {
        ...state,
        recipient: new Recipient(),
      }

    case beneficiaryConstants.CREATE_RECIPIENT_REQUEST:
      return {
        ...state,
        beneficiaryCreationLoadState: {
          isLoading: true,
          isLoaded: false,
        },
        error: undefined
        // isLoading: true,
      }
    case beneficiaryConstants.CREATE_RECIPIENT_SUCCESS:
      return {
        ...state,
        beneficiaryCreationLoadState: {
          isLoading: false,
          isLoaded: true,
        },
        recipient: new Recipient(),
      }
    case beneficiaryConstants.CREATE_RECIPIENT_FAILURE:
      return {
        ...state,
        beneficiaryCreationLoadState: {
          isLoading: false,
          isLoaded: false,
        },
        error: action.payload
      }

    case beneficiaryConstants.GET_RECIPIENT_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      }
    case beneficiaryConstants.GET_RECIPIENT_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        recipient: action.payload,
      }
    case beneficiaryConstants.GET_RECIPIENT_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case beneficiaryConstants.UPDATE_RECIPIENT_REQUEST:
      return {
        ...state,
        beneficiaryUpdateLoadState: {
          isLoading: true,
          isLoaded: false,
        },
        error: undefined
      }
    case beneficiaryConstants.UPDATE_RECIPIENT_SUCCESS:
      return {
        ...state,
        beneficiaryUpdateLoadState: {
          isLoading: false,
          isLoaded: true,
        },
      }
    case beneficiaryConstants.UPDATE_RECIPIENT_FAILURE:
      return {
        ...state,
        beneficiaryUpdateLoadState: {
          isLoading: false,
          isLoaded: false,
        },
        error: action.payload
      }
    case beneficiaryConstants.DELETE_RECIPIENT_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      }
    case beneficiaryConstants.DELETE_RECIPIENT_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        beneficiariesList: {
          hasNextPage: state.beneficiariesList.hasNextPage,
          items: state.beneficiariesList.items.filter(
            beneficiary => beneficiary.id !== action.payload
          ),
        },
      }
    case beneficiaryConstants.DELETE_RECIPIENT_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case beneficiaryConstants.GET_BENEFICIARIES_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoaded: false,
        isLoading: true,
        beneficiariesList: {
          hasNextPage: state.beneficiariesList.hasNextPage,
          items: action.offset === 0 ? [] : state.beneficiariesList.items,
        },
      }
    case beneficiaryConstants.GET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        beneficiariesList: {
          hasNextPage: action.payload.hasNextPage,
          items: [...state.beneficiariesList.items, ...action.payload.items],
        },
      }
    case beneficiaryConstants.GET_BENEFICIARIES_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case beneficiaryConstants.GET_BENEFICIARIES_BY_SEARCH_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoaded: false,
        isLoading: true,
        beneficiariesList: {
          hasNextPage: state.beneficiariesList.hasNextPage,
          items: action.offset === 0 ? [] : state.beneficiariesList.items,
        },
      }
    case beneficiaryConstants.GET_BENEFICIARIES_BY_SEARCH_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        beneficiariesList: {
          hasNextPage: action.payload.hasNextPage,
          items: [...state.beneficiariesList.items, ...action.payload.items],
          // action.offset === 0
          //   ? action.payload.items
          //   : [...state.beneficiariesList.items, ...action.payload.items],
        },
      }
    case beneficiaryConstants.GET_BENEFICIARIES_BY_SEARCH_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        // error: action.payload,
      }
    case beneficiaryConstants.CLEAR_BENEFICIARIES:
      return {
        ...state,
        beneficiariesList: { items: [], hasNextPage: false },
      }
    case beneficiaryConstants.LOOK_UP_BENEFICIARY_REQUEST:
      return {
        ...state,
        // error: undefined,
        isLookUpLoaded: false,
        isLookUpLoading: true,
      }
    case beneficiaryConstants.LOOK_UP_BENEFICIARY_SUCCESS:
      return {
        ...state,
        isLookUpLoaded: true,
        isLookUpLoading: false,
        recipient: { ...state.recipient, ...action.payload },
      }
    case beneficiaryConstants.SET_BENEFICIARY_TYPE:
      return {
        ...state,
        recipient: { ...state.recipient, type: action.payload },
      }
    case beneficiaryConstants.LOOK_UP_BENEFICIARY_FAILURE:
      return {
        ...state,
        isLookUpLoaded: false,
        isLookUpLoading: false,
      }
    case beneficiaryConstants.GET_BENEFICIARY_FIELDS_CONFIG_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoaded: false,
        isLoading: true,
      }
    case beneficiaryConstants.GET_BENEFICIARY_FIELDS_CONFIG_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        beneficiaryFieldsConfig: action.payload,
      }
    case beneficiaryConstants.GET_BENEFICIARY_FIELDS_CONFIG_FAILURE:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      }
    case beneficiaryConstants.GET_PROVIDER_LIST_FOR_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        providerListForWithdrawal: action.payload,
      }
    case beneficiaryConstants.VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_REQUEST:
      return {
        ...state,
        error: undefined,
        validateWithdrawalAccountNumberLoadState: {
          isLoading: true,
          isLoaded: false,
        },
        withdrawalAccountNumber: action.payload.accountNumber,
        withdrawalBankId: action.payload.bankId,
        withdrawalAccountNumberInfo: null,
      }
    case beneficiaryConstants.VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        validateWithdrawalAccountNumberLoadState: {
          isLoading: false,
          isLoaded: true,
        },
        withdrawalAccountNumberInfo: action.payload,
      }
    case beneficiaryConstants.VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_FAILURE:
      return {
        ...state,
        validateWithdrawalAccountNumberLoadState: {
          isLoading: false,
          isLoaded: false,
        },
        error: action.payload,
      }
    case beneficiaryConstants.CLEAR_WITHDRAWAL_ACCOUNT_INFO:
      return {
        ...state,
        withdrawalAccountNumber: '',
        withdrawalAccountNumberInfo: null,
        error: undefined,
      }
    case beneficiaryConstants.CREATE_MOBILE_WALLET_RECIPIENT_REQUEST:
      return {
        ...state,
        createMobileWalletRecipientLoadState: {
          isLoading: true,
          isLoaded: false,
        },
        createMobileWalletRecipientError: '',
      }
    case beneficiaryConstants.CREATE_MOBILE_WALLET_RECIPIENT_SUCCESS:
      return {
        ...state,
        createMobileWalletRecipientLoadState: {
          isLoading: false,
          isLoaded: true,
        }

      }
    case beneficiaryConstants.CREATE_MOBILE_WALLET_RECIPIENT_FAILURE:
      return {
        ...state,
        createMobileWalletRecipientLoadState: {
          isLoading: false,
          isLoaded: false,
        },
        createMobileWalletRecipientError: 'error',
      }
    case beneficiaryConstants.CLEAR_CREATE_MOBILE_WALLET_RECIPIENT_ERROR:
      return {
        ...state,
        createMobileWalletRecipientError: '',
      }

    case beneficiaryConstants.GET_COMPANY_LIST_FOR_BENEFICIARY_SUCCESS:
      return {
        ...state,
        companyListForBeneficiary: action.payload,
      }
    case beneficiaryConstants.GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_SUCCESS:
      return {
        ...state,
        accessTokenForAmlCompanyChecking: action.payload.token
      }
    default:
      return state
  }
}
