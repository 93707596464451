export enum beneficiaryConstants {
  GET_BENEFICIARY_COUNTRY_LIST_REQUEST = 'GET_BENEFICIARY_COUNTRY_LIST_REQUEST',
  GET_BENEFICIARY_COUNTRY_LIST_SUCCESS = 'GET_BENEFICIARY_COUNTRY_LIST_SUCCESS',
  GET_BENEFICIARY_COUNTRY_LIST_FAILURE = 'GET_BENEFICIARY_COUNTRY_LIST_FAILURE',

  GET_COUNTRY_ALL_LIST_REQUEST = 'GET_COUNTRY_ALL_LIST_REQUEST',
  GET_COUNTRY_ALL_LIST_SUCCESS = 'GET_COUNTRY_ALL_LIST_SUCCESS',
  GET_COUNTRY_ALL_LIST_FAILURE = 'GET_COUNTRY_ALL_LIST_FAILURE',

  CLEAR_RECIPIENT_SUCCESS = 'CLEAR_RECIPIENT_SUCCESS',

  CREATE_RECIPIENT_REQUEST = 'CREATE_RECIPIENT_REQUEST',
  CREATE_RECIPIENT_SUCCESS = 'CREATE_RECIPIENT_SUCCESS',
  CREATE_RECIPIENT_FAILURE = 'CREATE_RECIPIENT_FAILURE',

  CREATE_MOBILE_WALLET_RECIPIENT_REQUEST = 'CREATE_MOBILE_WALLET_RECIPIENT_REQUEST',
  CREATE_MOBILE_WALLET_RECIPIENT_SUCCESS = 'CREATE_MOBILE_WALLET_RECIPIENT_SUCCESS',
  CREATE_MOBILE_WALLET_RECIPIENT_FAILURE = 'CREATE_MOBILE_WALLET_RECIPIENT_FAILURE',

  CLEAR_CREATE_MOBILE_WALLET_RECIPIENT_ERROR = 'CLEAR_CREATE_MOBILE_WALLET_RECIPIENT_ERROR',

  GET_RECIPIENT_REQUEST = 'GET_RECIPIENT_REQUEST',
  GET_RECIPIENT_SUCCESS = 'GET_RECIPIENT_SUCCESS',
  GET_RECIPIENT_FAILURE = 'GET_RECIPIENT_FAILURE',

  UPDATE_RECIPIENT_REQUEST = 'UPDATE_RECIPIENT_REQUEST',
  UPDATE_RECIPIENT_SUCCESS = 'UPDATE_RECIPIENT_SUCCESS',
  UPDATE_RECIPIENT_FAILURE = 'UPDATE_RECIPIENT_FAILURE',

  DELETE_RECIPIENT_REQUEST = 'DELETE_RECIPIENT_REQUEST',
  DELETE_RECIPIENT_SUCCESS = 'DELETE_RECIPIENT_SUCCESS',
  DELETE_RECIPIENT_FAILURE = 'DELETE_RECIPIENT_FAILURE',

  GET_BENEFICIARIES_REQUEST = 'GET_BENEFICIARIES_REQUEST',
  GET_BENEFICIARIES_SUCCESS = 'GET_BENEFICIARIES_SUCCESS',
  GET_BENEFICIARIES_FAILURE = 'GET_BENEFICIARIES_FAILURE',

  GET_BENEFICIARIES_BY_SEARCH_REQUEST = 'GET_BENEFICIARIES_BY_SEARCH_REQUEST',
  GET_BENEFICIARIES_BY_SEARCH_SUCCESS = 'GET_BENEFICIARIES_BY_SEARCH_SUCCESS',
  GET_BENEFICIARIES_BY_SEARCH_FAILURE = 'GET_BENEFICIARIES_BY_SEARCH_FAILURE',

  CLEAR_BENEFICIARIES = 'CLEAR_BENEFICIARIES',

  // CLEAR_SEARCHED_DATA = 'CLEAR_SEARCHED_DATA',

  // SAVE_SEARCHED_DATA = 'SAVE_SEARCHED_DATA',

  LOOK_UP_BENEFICIARY_REQUEST = 'LOOK_UP_BENEFICIARY_REQUEST',
  LOOK_UP_BENEFICIARY_SUCCESS = 'LOOK_UP_BENEFICIARY_SUCCESS',
  LOOK_UP_BENEFICIARY_FAILURE = 'LOOK_UP_BENEFICIARY_FAILURE',

  GET_BENEFICIARY_FIELDS_CONFIG_REQUEST = 'GET_BENEFICIARY_FIELDS_CONFIG_REQUEST',
  GET_BENEFICIARY_FIELDS_CONFIG_SUCCESS = 'GET_BENEFICIARY_FIELDS_CONFIG_SUCCESS',
  GET_BENEFICIARY_FIELDS_CONFIG_FAILURE = 'GET_BENEFICIARY_FIELDS_CONFIG_FAILURE',

  SET_BENEFICIARY_TYPE = 'SET_BENEFICIARY_TYPE',

  GET_PROVIDER_LIST_FOR_WITHDRAWAL_REQUEST = 'GET_PROVIDER_LIST_FOR_WITHDRAWAL_REQUEST',
  GET_PROVIDER_LIST_FOR_WITHDRAWAL_SUCCESS = 'GET_PROVIDER_LIST_FOR_WITHDRAWAL_SUCCESS',
  GET_PROVIDER_LIST_FOR_WITHDRAWAL_FAILURE = 'GET_PROVIDER_LIST_FOR_WITHDRAWAL_FAILURE',

  VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_REQUEST = 'VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_REQUEST',
  VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_SUCCESS = 'VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_SUCCESS',
  VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_FAILURE = 'VALIDATE_WITHDRAWAL_ACCOUNT_NUMBER_FAILURE',

  CLEAR_WITHDRAWAL_ACCOUNT_INFO = 'CLEAR_WITHDRAWAL_ACCOUNT_INFO',

  GET_COMPANY_LIST_FOR_BENEFICIARY_REQUEST = 'GET_COMPANY_LIST_FOR_BENEFICIARY_REQUEST',
  GET_COMPANY_LIST_FOR_BENEFICIARY_SUCCESS = 'GET_COMPANY_LIST_FOR_BENEFICIARY_SUCCESS',
  GET_COMPANY_LIST_FOR_BENEFICIARY_FAILURE = 'GET_COMPANY_LIST_FOR_BENEFICIARY_FAILURE',

  GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_REQUEST = 'GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_REQUEST',
  GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_SUCCESS = 'GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_SUCCESS',
  GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_FAILURE = 'GET_AML_ACCESS_TOKEN_FOR_COMPANY_BENEFICIARY_FAILURE'
}
