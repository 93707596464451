import { isIndividualBeneficiaryFormType } from '../components/beneficiaries/beneficiaryFormUtils'
import { CustomErrorResponse } from '../interfaces/api/axiosTypes'
import { CompanyBeneficiaryFormType, CompanyRecipient, GetBeneficiaryFieldsConfigResponse, IndividualBeneficiaryFormType, KWLRecipient } from '../interfaces/beneficiary'
import { documentTypeOptionList } from './configs/optionConfigs'
import isExists from 'date-fns/isExists'

export const trimObjectFields = <T extends object>(object: T) => {
  return Object.keys(object).reduce((acc, key) => {
    acc[key] = typeof object[key] == 'string' ? object[key].trim() : object[key]
    return acc
  }, {} as T)
}

export const convertFormDataDependingOnBeneficiaryConfig = (
  formData: IndividualBeneficiaryFormType | Omit<CompanyBeneficiaryFormType, 'countryId'>,
  config: GetBeneficiaryFieldsConfigResponse
) => {
  const isIndividualRecipient = isIndividualBeneficiaryFormType(formData)
  let newFormData = {} as IndividualBeneficiaryFormType | Omit<CompanyBeneficiaryFormType, 'countryId'>


  if (isIndividualRecipient) {
    newFormData = { ...formData }

    for (const propKey in formData) {
      const configName = propKey.replace(propKey[0], propKey[0].toUpperCase())

      if (configName === 'DocumentType') {
        documentTypeOptionList(config).length === 0 && isIndividualRecipient && delete newFormData.documentType
        continue
      }

      if (configName === 'DocumentCountryIso') {
        !config.Individual.DocumentIssuingCountry.visible && delete newFormData.documentCountryIso
        continue
      }

      if (configName === 'CountryId') {
        !config.Individual.AddressCountry.visible && delete newFormData.countryId
        continue
      }

      if (configName === 'City') {
        !config.Individual.AddressCity.visible && delete newFormData.city
        continue
      }

      if (configName === 'Street') {
        !config.Individual.AddressStreet.visible && delete newFormData.street
        continue
      }

      if (configName === 'Postcode') {
        !config.Individual.AddressPostCode.visible && delete newFormData.postcode
        continue
      }
      if (configName === 'BankId') {
        !config.Individual.BankName.visible && delete newFormData.bankId
        continue
      }

      !config.Individual[configName].visible && delete newFormData[propKey]
    }

  } else {
    newFormData = { ...formData }

    for (const propKey in formData) {
      const configName = propKey.replace(propKey[0], propKey[0].toUpperCase())

      if (configName === 'BankId') {
        !config.Company.BankName.visible && delete newFormData.bankId
        continue
      }

      if(configName === 'CountryId') {
        continue
      }

      !config.Company[configName].visible && delete newFormData[propKey]
    }
  }

  return newFormData
}


export const detectMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
}

//for flutter android app
export const detectMobileApp = () => {
  const appMarker = 'AndroidApp'

  return navigator.userAgent.includes(appMarker)
}

export const shouldItUseShowPicker = () => {
  const toMatch = [
    /Firefox/i,
    // /iPhone/i,
    // /iPad/i,
  ]

  return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
}

export const setAttributes = (el: HTMLElement, attrs: {}) => {
  for (var key in attrs) {
    el.setAttribute(key, attrs[key])
  }
}

export const isCustomErrorResponse = (
  error: CustomErrorResponse | undefined | string
): error is CustomErrorResponse => {
  if (error && typeof error !== 'string') {
    return (error as CustomErrorResponse).errorCode !== undefined
  } else {
    return false
  }
}

export const checkForDateExistence = (date: string | undefined) => {
  if (!date) {
    return false
  }
  const [year, month, day] = date.split('-').map(dateItem => Number(dateItem))
  const currentYear = new Date().getFullYear()

  // if (date.trim().length !== 10) {
  //   return false
  // }
  if (date.trim().length !== 10 || !isExists(Number(year), Number(month) - 1, Number(day))) {
    return false
  }

  return currentYear > Number(year) && Number(year) >= 1930
}
